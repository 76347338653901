import request from "../utils/request";

export function schoolYear() {
  return request({
    method: "post",
    url: "school-year/get",
  });
}

export function grades() {
  return request({
    method: "post",
    url: "grade/get",
  });
}

export function classroom(data) {
  return request({
    method: "post",
    url: "classroom/get",
    data,
  });
}

export function subjects(data) {
  return request({
    method: "post",
    url: "subject/get",
    data,
  });
}

export function masterSubDistrict(data) {
  return request({
    method: "post",
    url: "/address/sub-district",
    data,
  });
}

export function masterCategory(data) {
  return request({
    method: "post",
    url: "/categories/get",
    data,
  });
}

export function addMasterCategory(data) {
  return request({
    method: "post",
    url: "/categories/add",
    data,
  });
}

export function editMasterCategory(data) {
  return request({
    method: "post",
    url: "/categories/edit",
    data,
  });
}

export function deleteMasterCategory(data) {
  return request({
    method: "post",
    url: "/categories/delete",
    data,
  });
}
