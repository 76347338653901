import request from "../utils/request";

export function uploadFile(data) {
  return request({
    method: "post",
    url: "/file/upload",
    header: { "Content-Type": "multipart/form-data" },
    data,
  });
}

export function removeFile(data) {
  return request({
    method: "post",
    url: "/file/remove",
    data,
  });
}
