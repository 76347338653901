import request from "@/utils/request";

export function getEtestList(data) {
  return request({
    method: "post",
    url: "e-test/list",
    data: data,
  });
}

export function createEtest(data) {
  return request({
    method: "post",
    url: "e-test/add",
    data: data,
  });
}

export function deleteEtest(data) {
  return request({
    method: "post",
    url: "e-test/delete",
    data: data,
  });
}

export function editEtest(data) {
  return request({
    method: "post",
    url: "e-test/edit",
    data: data,
  });
}

export function detailEtest(data) {
  return request({
    method: "post",
    url: "e-test/detail",
    data: data,
  });
}

export function studentEtestList(data) {
  return request({
    method: "post",
    url: "e-test/student/list",
    data: data,
  });
}

export function startEtest(data) {
  return request({
    method: "post",
    url: "e-test/student/start",
    data: data,
  });
}

export function getEtestStudent(data) {
  return request({
    method: "post",
    url: "e-test/student/get",
    data: data,
  });
}

export function finishEtest(data) {
  return request({
    method: "post",
    url: "e-test/student/finish",
    data: data,
  });
}

export function answerEtest(data) {
  return request({
    method: "post",
    url: "e-test/student/answer",
    data: data,
  });
}

export function etestParticipants(data) {
  return request({
    method: "post",
    url: "e-test/participants",
    data: data,
  });
}

export function etestCorrection(data) {
  return request({
    method: "post",
    url: "e-test/work-sheet",
    data: data,
  });
}

export function updateScore(data) {
  return request({
    method: "post",
    url: "e-test/edit-score",
    data: data,
  });
}
