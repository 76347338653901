<template>
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-btn icon class="mr-2" @click="$router.push({ name: 'E-Test' })">
          <v-icon large>mdi-arrow-left</v-icon>
        </v-btn>
        E-Test Baru
        <v-spacer />
        <v-btn :loading="loadingSave" class="primary" depressed @click="save()">
          Simpan
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" class="pa-5">
          <v-row>
            <v-col cols="12" class="d-flex">
              <v-select
                v-model="schoolYear"
                :items="listSchoolYear"
                :loading="loadingSchoolYear"
                :rules="[(v) => !!v || 'Tahun Ajaran harus diisi']"
                item-value="id"
                dense
                label="Tahun Ajaran"
                class="mr-2"
                outlined
              >
                <template #item="{ item }">
                  <span class="caption">
                    {{ item.start_year }} / {{ item.end_year }}, semester
                    {{ item.semester }}
                  </span>
                </template>
                <template #selection="{ item }">
                  <span class="caption mr-2">
                    {{ item.start_year }} / {{ item.end_year }}, semester
                    {{ item.semester }}
                  </span>
                </template>
              </v-select>
              <v-select
                v-model="grade"
                :items="listGrade"
                :loading="loadingGrade"
                :rules="[(v) => !!v || 'Tingkatan harus diisi']"
                item-text="grade_name"
                item-value="id"
                dense
                label="Tingkatan"
                class="mr-2"
                outlined
              />
              <v-select
                v-model="dataClass"
                :items="listClassroom"
                :loading="loadingClassroom"
                :rules="[(v) => !!v || 'Kelas harus diisi']"
                item-value="id"
                item-text="name"
                dense
                label="Kelas"
                class="mr-2"
                outlined
              />
              <v-select
                v-model="subject"
                :items="listSubjects"
                :loading="loadingSubjects"
                :rules="[(v) => !!v || 'Mata Pelajaran harus diisi']"
                item-value="name"
                item-text="name"
                dense
                label="Mata Pelajaran"
                class="mr-2"
                outlined
              />
              <v-text-field
                v-model="duration"
                :rules="[(v) => !!v || 'Durasi harus diisi']"
                label="Durasi (Menit)"
                type="number"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    :rules="[(v) => !!v || 'Tanggal Mulai harus diisi']"
                    label="Tanggal Mulai"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="startDate" :min="todayDate" />
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <v-menu
                ref="menuEndDate"
                v-model="menuEndDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    :rules="[(v) => !!v || 'Tanggal Berakhir harus diisi']"
                    :disabled="!startDate ? true : false"
                    label="Tanggal Berakhir"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="endDate" :min="startDate" />
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <TimePicker
                :rules="[(v) => !!v || 'Jam Mulai harus diisi']"
                :initValue="startHours"
                label="Jam Mulai"
                outlined
                dense
                @value="(v) => (startHours = v)"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <TimePicker
                :rules="[(v) => !!v || 'Jam Berakhir harus diisi']"
                :initValue="endHours"
                :isDisabled="!startHours ? true : false"
                label="Jam Berakhir"
                outlined
                dense
                @value="(v) => (endHours = v)"
              />
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                :rules="[(v) => !!v || 'Judul E-Test harus diisi']"
                outlined
                dense
                label="Judul E-Test"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" class="pa-5">
          <div class="font-weight-bold mb-2">Soal</div>
          <quill-editor
            v-model="form[questionCurrentIndex].question"
            ref="myQuillEditor"
            id="question"
            :options="editorOption"
            aria-placeholder="Soal"
          />
          <v-tabs
            v-model="form[questionCurrentIndex].tab"
            class="mt-5 mb-2"
            id="answer-question"
          >
            <v-tab class="font-weight-bold"> Pilihan Ganda </v-tab>
            <v-tab class="font-weight-bold"> Essay </v-tab>
          </v-tabs>
          <v-divider class="mt-n2 mb-5" />
          <v-tabs-items v-model="form[questionCurrentIndex].tab">
            <v-tab-item>
              <div>
                <v-radio-group v-model="form[questionCurrentIndex].key">
                  <v-row
                    v-for="(item, index) in form[questionCurrentIndex].options"
                    :key="index"
                    align="center"
                    class="pt-4"
                    no-gutters
                  >
                    <v-col cols="12" sm="7" class="d-flex">
                      <v-radio :value="item.key" />
                      <v-text-field
                        v-model="item.description"
                        :label="`Jawaban ${item.key}`"
                        hide-details
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="12" sm="5" class="d-flex align-center">
                      <ButtonUpload
                        :loading="item.loading"
                        depressed
                        class="mx-2"
                        @getDocument="
                          (fileData) => uploadDocument(fileData, index)
                        "
                      />
                      <div v-if="item.file" class="d-flex">
                        <v-chip
                          close
                          color="primary"
                          class="ma-2"
                          @click:close="item.file = null"
                          @click="openFile(item.file)"
                        >
                          {{ item.file.replace(/^.*[\\\/]/, "") }}
                        </v-chip>
                      </div>
                      <div v-else class="grey--text">Belum ada Gambar</div>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </div>
              <v-divider class="mb-5" />
              <div class="ml-4">
                <div class="font-weight-bold">
                  Kunci Jawaban (Klik Radio Button di atas untuk menentukan
                  kunci jawaban)
                </div>
                <span
                  v-html="form[questionCurrentIndex].key"
                  class="font-weight-bold"
                />
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="font-weight-bold mb-1">Kunci Jawaban</div>
              <quill-editor
                v-if="form[questionCurrentIndex].tab"
                v-model="form[questionCurrentIndex].key"
                ref="myQuillEditor"
                :options="editorOption"
                aria-placeholder="Kunci Jawaban"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-divider class="my-5" />
          <div class="font-weight-bold mt-4 mb-1">Penjelasan Soal</div>
          <quill-editor
            v-model="form[questionCurrentIndex].description"
            ref="myQuillEditor"
            :options="editorOption"
            class="mb-4"
            aria-placeholder="Penjelasan Soal"
          />
          <v-text-field
            v-model="form[questionCurrentIndex].point"
            :rules="[(v) => !!v || 'Bobot Soal harus diisi']"
            outlined
            dense
            label="Bobot Soal"
          />
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-n3">
        <v-card elevation="0" class="pa-5">
          <v-row class="pa-2">
            <v-col cols="12" sm="4">
              <v-btn
                :disabled="questionCurrentIndex === 0"
                color="blue-grey darken"
                outlined
                depressed
                @click="previous()"
              >
                Sebelumnya
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="font-weight-bold">
                Pertanyaan {{ questionCurrentIndex + 1 }} dari
                {{ form.length }} Soal
              </span>
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-sm-end">
              <v-btn dark class="indigo darken-4" depressed @click="next()">
                Selanjutnya
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  ImageExtend,
  QuillWatch,
  container,
} from "@/plugins/quill-image-extend-module";
import { schoolYear, grades, classroom, subjects } from "@/api/master";
import { uploadFile } from "@/api/file";
import { createEtest, detailEtest, editEtest } from "@/api/etest";
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
import { getToken } from "@/utils/storage";

Quill.register("modules/ImageExtend", ImageExtend);

export default {
  components: {
    quillEditor,
    ButtonUpload: () => import("@/components/UploadFile"),
    TimePicker: () => import("@/components/TimePicker"),
  },
  created() {
    this.appendFormData();
    this.getSchoolYear();
    this.getGrades();
    this.getClassroom();
    this.getSubjects();
    if (this.$route.query.id) {
      this.getDataById(this.$route.query.id);
    }
  },
  data() {
    return {
      todayDate: new Date().toISOString().slice(0, 10),
      loadingSchoolYear: false,
      loadingSave: false,
      loadingSubjects: false,
      loadingGrade: false,
      loadingClassroom: false,
      listSchoolYear: [],
      listGrade: [],
      listClassroom: [],
      listSubjects: [],
      questionData: {
        question: "",
        point: 0,
        tab: 0,
        description: "",
        key: "",
        files: [],
        options: [
          { description: "", file: null, key: "A", loading: false },
          { description: "", file: null, key: "B", loading: false },
          { description: "", file: null, key: "C", loading: false },
          { description: "", file: null, key: "D", loading: false },
          { description: "", file: null, key: "E", loading: false },
        ],
      },
      questionCurrentIndex: 0,
      menuStartDate: false,
      menuEndDate: false,
      startDate: null,
      endDate: null,
      title: null,
      schoolYear: null,
      subjectsData: null,
      grade: null,
      dataClass: null,
      class: null,
      subject: null,
      duration: null,
      startHours: null,
      endHours: null,
      form: [],
      editorOption: {
        modules: {
          toolbar: {
            container: container,
            handlers: {
              image: function () {
                QuillWatch.emit(this.quill.id);
              },
            },
          },
          ImageExtend: {
            loading: true,
            name: "file",
            action: process.env.VUE_APP_BASE_API + "file/upload",
            headers: (xhr) => {
              xhr.setRequestHeader("Authorization", "Bearer " + getToken());
            },
            response: (res) => {
              return res.data.path;
            },
          },
        },
      },
    };
  },
  methods: {
    openFile(file) {
      window.open(file, "_blank");
    },
    removeFile(array, item) {
      array.splice(array.indexOf(item), 1);
    },
    async uploadDocument(file, index) {
      const multipleChooice =
        this.form[this.questionCurrentIndex].options[index];
      multipleChooice.loading = true;
      const body = new FormData();
      body.append("file", file);

      uploadFile(body)
        .then((res) => {
          const data = res.data;
          if (data.code) {
            const url = data.data.path;
            multipleChooice.file = url;
          }
        })
        .finally(() => (multipleChooice.loading = false));
    },
    getSubjects() {
      this.loadingSubjects = true;
      subjects()
        .then((res) => {
          if (res.data.code) {
            this.listSubjects = res.data.data;
          }
        })
        .finally(() => (this.loadingSubjects = false));
    },
    getClassroom() {
      this.loadingClassroom = true;
      classroom()
        .then((res) => {
          if (res.data.code) {
            this.listClassroom = res.data.data;
          }
        })
        .finally(() => (this.loadingClassroom = false));
    },
    getGrades() {
      this.loadingGrade = true;
      grades()
        .then((res) => {
          if (res.data.code) {
            this.listGrade = res.data.data;
          }
        })
        .finally(() => (this.loadingGrade = false));
    },
    getSchoolYear() {
      this.loadingSchoolYear = true;
      schoolYear()
        .then((res) => {
          if (res.data.code) {
            const data = res.data.data;
            this.listSchoolYear = data.reverse();
            const active = data.find((e) => e.is_active);
            if (active) {
              this.schoolYear = active.id;
            }
          }
        })
        .finally(() => (this.loadingSchoolYear = false));
    },
    scroll(el) {
      document
        .querySelector(el ? el : "#question")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
    appendFormData() {
      const data = JSON.parse(JSON.stringify(this.questionData));
      this.form.push(data);
    },
    next() {
      const questionData = this.form[this.questionCurrentIndex];
      if (this.$refs.form.validate()) {
        if (questionData.question) {
          if (questionData.key) {
            const nextIndex = this.questionCurrentIndex + 1;
            if (nextIndex === this.form.length) {
              this.appendFormData();
            }
            this.questionCurrentIndex = nextIndex;
            this.scroll();
          } else {
            this.$nextTick(() => {
              this.scroll("#answer-question");
            });
            this.snackBar(false, "Kunci Jawaban harus diisi");
          }
        } else {
          this.$nextTick(() => {
            this.scroll("#question");
          });
          this.snackBar(false, "Soal harus diisi");
        }
      } else {
        this.$nextTick(() => {
          this.scroll(".v-messages.error--text");
        });
      }
    },
    previous() {
      this.questionCurrentIndex -= 1;
      this.scroll();
    },
    save() {
      const formEtest = {
        school_year: this.schoolYear,
        classroom: this.dataClass,
        grade: this.grade,
        subject: this.subject,
        title: this.title,
        start_time: parseInt(
          moment(this.startDate.concat(" " + this.startHours)).valueOf()
        ),
        end_time: parseInt(
          moment(this.endDate.concat(" " + this.endHours)).valueOf()
        ),
        duration: this.duration,
        questions: JSON.parse(JSON.stringify(this.form)).map((e) => ({
          ...e,
          type: e.tab === 0 ? "option" : "esay",
          options: e.options.filter((v) => v.description),
        })),
      };
      if (this.$refs.form.validate()) {
        if (this.form[this.questionCurrentIndex].key) {
          this.loadingSave = true;
          if (this.$route.query.id) {
            editEtest({ ...formEtest, id: this.$route.query.id })
              .then((res) => {
                if (res.data.code) {
                  this.snackBar(true, "Berhasil edit E-Test");
                  this.$router.push({ name: "E-Test" });
                } else {
                  this.snackBar(false, res.data.message);
                }
              })
              .finally(() => (this.loadingSave = false));
          } else {
            createEtest(formEtest)
              .then((res) => {
                if (res.data.code) {
                  this.snackBar(true, "Berhasil membuat E-Test");
                  this.$router.push({ name: "E-Test" });
                } else {
                  this.snackBar(false, "Gagal membuat E-Test");
                }
              })
              .finally(() => (this.loadingSave = false));
          }
        } else {
          this.$nextTick(() => {
            this.scroll("#answer-question");
          });
          this.snackBar(false, "Kunci Jawaban harus diisi");
        }
      } else {
        this.$nextTick(() => {
          this.scroll(".v-messages.error--text");
        });
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    getDataById(id) {
      detailEtest({ id: id }).then((res) => {
        if (res.data.code) {
          const data = res.data.data;
          this.schoolYear = parseInt(data.school_year);
          this.dataClass = parseInt(data.classroom);
          this.grade = parseInt(data.grade_id);
          this.subject = data.subject;
          this.title = data.title;
          this.startDate = this.dateFromEpoch(data.start_time);
          this.endDate = this.dateFromEpoch(data.end_time);
          this.startHours = this.timeFromEpoch(data.start_time);
          this.endHours = this.timeFromEpoch(data.end_time);
          this.duration = data.duration;
          this.form = data.questions.map((e) => ({
            ...e,
            tab: e.type === "option" ? 0 : 1,
            options: e.options.length ? e.options : this.questionData.options,
          }));
        }
      });
    },
    dateFromEpoch(date) {
      return moment
        .unix(parseInt(date.toString().substr(0, 10)))
        .format("YYYY-MM-DD");
    },
    timeFromEpoch(date) {
      return moment
        .unix(parseInt(date.toString().substr(0, 10)))
        .format("HH:mm");
    },
  },
};
</script>

<style lang="scss">
#duration {
  border-radius: 5px;
  height: 40px;
  border-color: #a0a0a0;
}
</style>
